<template>
  <div class="content">
    <v-row justify="space-between" class="ma-0 pa-0">
      <v-img
        class="title ml-1 my-auto"
        max-width="120"
        max-height="18"
        :src="levelImg"
      ></v-img>
      <v-btn text class="body--text f14" @click="showMore"
        >查看更多
        <v-icon size="18">icon-right-arrow</v-icon>
      </v-btn>
    </v-row>
    <v-lazy v-model="isShow" class="mt-4">
      <v-row>
        <v-col
          v-for="book in books"
          :key="book.id"
          cols="auto"
          md="6"
          sm="12"
          lg="4"
          class="mx-auto"
        >
          <div
            class="item d-flex algin-center"
            @click="itemClickHandler(book.id)"
          >
            <c-img-book-cover
              :src="book.cover"
              max-width="74"
              max-height="98"
              width="74"
              height="98"
              class="my-auto ml-2"
              :title="book.name"
            ></c-img-book-cover>
            <div class="ml-3">
              <v-clamp
                class="mt-4 common--text font-weight-medium f18"
                autoresize
                :max-lines="2"
                >{{ book.name }}</v-clamp
              >
              <v-clamp autoresize :max-lines="1" class="mt-2 f14 body--text">{{
                book.writer
              }}</v-clamp>
            </div>
          </div>
        </v-col>
        <v-spacer v-if="showInBigScreen"></v-spacer>
      </v-row>
    </v-lazy>
  </div>
</template>

<script>
import VClamp from "vue-clamp";
import { getPCBookList } from "@/api/api-pc.js";
import CImgBookCover from "@/components/c-img-book-cover/c-img-book-cover";
export default {
  name: "pc-recommend-book-list",
  props: {
    level: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isShow: false,
      page: 1,
      limit: 6,
      books: [],
    };
  },
  components: { VClamp, CImgBookCover },
  computed: {
    levelImg() {
      return require("@/assets/level_gold/level_gold_" + this.level + ".png");
    },
    showInBigScreen() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
  methods: {
    showMore() {
      const { level } = this;
      this.$router.push({
        name: "MoreBooks",
        query: { level: level },
      });
    },
    itemClickHandler(bid) {
      this.$router.push({
        name: "pcBookDetail",
        params: {
          bookId: bid.toString(),
        },
      });

      this.$track.event({
        category: "book",
        name: "visit",
        value: bid.toString(),
        params: {
          referer_action: "main_page",
          tag: this.level.toString(),
        },
      });
    },
  },
  watch: {
    isShow(val) {
      getPCBookList(this.page, this.limit, this.level).then((res) => {
        this.books = res.books;
      });
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.content {
  margin-top: 50px;
  width: 100%;
}
.item {
  width: 361px;
  height: 127px;
  background: white;
  border-radius: 4px;
  &:hover {
    background: linear-gradient(
      270deg,
      rgba(251, 252, 253, 0.11) 0%,
      rgba(201, 201, 201, 0.3) 100%
    );
  }
}
</style>
