<template>
  <pc-frame class="index" :class="dialog ? 'blurred' : ''">
    <template>
      <div class="head">
        <v-img
          class="mx-auto"
          style="margin-top:80px"
          max-width="154"
          src="@/assets/img_hy_icon_text.png"
        ></v-img>
        <v-text-field
          hide-details
          color="minor"
          rounded
          placeholder="搜索"
          @click="searchTextClickHandler"
          class="search-text mx-auto  f16"
        >
          <v-icon slot="prepend-inner" size="18px" color="info"
            >icon-search</v-icon
          >
        </v-text-field>
      </div>
      <div class="content">
        <div class="list-content">
          <pc-recommend-book-part v-if="!isTeacher"></pc-recommend-book-part>
          <pc-level-book-part></pc-level-book-part>
        </div>
        <p class="text-center copy-right">
          Copyright © {{ year }} Hongyear Information Technology (Shanghai)
          Co.,Ltd. All rights reserved
        </p>
      </div>
    </template>
    <pc-reader-book-search :dialog.sync="dialog"></pc-reader-book-search>
  </pc-frame>
</template>

<script>
import PcFrame from "@/views/pc/index/pc-frame.vue";
import PcRecommendBookPart from "@/views/pc/index/pc-recommend-book-part.vue";
import PcLevelBookPart from "@/views/pc/index/pc-level-book-part.vue";
import PcReaderBookSearch from "@/views/pc/components/pc-reader-book-search.vue";
import PcMixins from "@/views/pc/_mixins/pc-mixins.js";
import UserDataMixins from "@/views/pc/index/_mixins/pc-user-mixins.js";
export default {
  mixins: [PcMixins, UserDataMixins],
  data() {
    return {
      dialog: false,
    };
  },
  created() {},
  methods: {
    searchTextClickHandler() {
      this.dialog = true;
    },
  },
  computed: {
    year() {
      return this.$moment(new Date()).year();
    },
  },
  components: {
    PcFrame,
    PcRecommendBookPart,
    PcLevelBookPart,
    PcReaderBookSearch,
  },
};
</script>

<style lang="scss" scoped>
.index {
  background: #f6f7f9;
  &.blurred {
    filter: blur(10px);
  }
}
.head {
  height: 232px;
}
.content {
  background-color: white;
  padding-top: 50px;
}

.search-text {
  max-width: 796px;
  height: 50px;
  background: white;
  margin-top: 50px;
  box-shadow: 0px 1px 12px 0px rgba(171, 171, 171, 0.2);
}

::v-deep .v-text-field input {
  padding: 2.5px;
}

.list-content {
  margin: auto;
  max-width: 1200px;
  background-color: white;
}

.copy-right {
  margin-top: 160px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #707f8d;
  line-height: 22px;
}

body {
  overflow: hidden;
}
</style>
