<template>
  <div v-if="books.length > 0">
    <v-row justify="space-between" class="mx-2">
      <v-card-title class="f18 common--text pa-0 ma-0">推荐书籍</v-card-title>
      <v-spacer></v-spacer>
      <div>
        <v-btn
          text
          class="f14 body--text"
          @click="lastPageClickHandler"
          :disabled="stopLast"
        >
          <v-icon>icon-uniE939</v-icon></v-btn
        >
        <v-btn
          text
          class="f14 body--text"
          @click="nextPageClickHandler"
          :disabled="stopNext"
          ><v-icon>icon-right-arrow</v-icon></v-btn
        >
      </div>
    </v-row>
    <v-container fluid class="pa-0 ma-0">
      <v-row class="pa-0 ma-0">
        <v-col
          v-for="book in books"
          :key="book.id"
          cols="12"
          md="4"
          sm="6"
          lg="3"
          class="pa-0 ma-0"
        >
          <div class="item px-8" @click="itemClickHandler(book.id)">
            <c-img-book-cover
              :src="book.cover"
              max-width="90"
              max-height="118"
              width="90"
              height="118"
              :title="book.name"
              class="mx-auto"
            ></c-img-book-cover>
            <v-clamp
              class="text-center mt-7 f18 common--text font-weight-medium"
              autoresize
              :max-lines="2"
              >{{ book.name }}</v-clamp
            >
            <v-clamp
              autoresize
              :max-lines="1"
              class="text-center mt-3 f14 body--text"
              >{{ book.writer }}</v-clamp
            >
            <v-clamp
              autoresize
              :max-lines="1"
              class="text-center mt-3 f14 body--text"
              >{{ book.desc }}</v-clamp
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VClamp from "vue-clamp";
import CImgBookCover from "@/components/c-img-book-cover/c-img-book-cover";
import { getPCBookList } from "@/api/api-pc.js";
import UserDataMixins from "@/views/pc/index/_mixins/pc-user-mixins.js";
export default {
  mixins: [UserDataMixins],
  name: "pc-recommend-book-part",
  props: {},
  data() {
    return {
      page: 1,
      limit: 4,
      books: [],
      pagination: {},
    };
  },
  components: { VClamp, CImgBookCover },
  computed: {
    stopLast() {
      return this.pagination.currentPage == 1;
    },
    stopNext() {
      return this.pagination.currentPage == this.pagination.totalPages;
    },
  },
  methods: {
    getBooks() {
      getPCBookList(this.page, this.limit, this.grade).then((res) => {
        this.books = res.books;
        this.pagination = res.meta.pagination;
      });
    },
    lastPageClickHandler() {
      this.page--;
      this.getBooks();
    },
    nextPageClickHandler() {
      this.page++;
      this.getBooks();
    },
    itemClickHandler(bid) {
      this.$router.push({
        name: "pcBookDetail",
        params: {
          bookId: bid.toString(),
        },
      });

      this.$track.event({
        category: "book",
        name: "visit",
        value: bid.toString(),
        params: {
          referer_action: "main_page",
        },
      });
    },
  },
  watch: {
    grade(val) {
      this.getBooks();
    },
    page(val) {
      if (val == 1) {
        this.getBooks();
      }
    },
  },
  created() {
    this.getBooks();
  },
};
</script>

<style lang="scss" scoped>
.item {
  margin: 16px auto 0px auto;
  padding: 34px 0px 0px 9px;
  width: 286px;
  height: 316px;
  background: #f6f7f9;
  border-radius: 4px;
}
</style>
