<template>
  <v-list>
    <v-list-item v-for="(level, i) in levels" :key="i">
      <pc-level-book-list :level="level"></pc-level-book-list>
    </v-list-item>
  </v-list>
</template>

<script>
import PcLevelBookList from "@/views/pc/index/pc-level-book-list.vue";
import { getVisibleLevels } from "@/api/api-pc.js";
export default {
  name: "pc-level-book-part",
  props: {},
  data() {
    return { levels: [] };
  },
  components: { PcLevelBookList },
  computed: {},
  methods: {},
  watch: {},
  created() {
    getVisibleLevels().then((res) => {
      this.levels = res.levels;
    });
  },
};
</script>

<style lang="scss" scoped></style>
